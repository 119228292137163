import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Offcanvas from "react-bootstrap/Offcanvas";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";

import "../components/NavigationBar.css";

import "./Characters.css";

const styledLink = {
  textDecoration: "none",
  color: "var(--bs-light)",
};

const fabStyle = {
  position: "fixed",
  bottom: "3%",
  left: "2%",
};

function NavPane({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <>
      <Fab style={fabStyle} onClick={toggleShow}>
        <MenuIcon />
      </Fab>
      <Offcanvas
        show={show}
        scroll={true}
        restoreFocus={false}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Jump to...</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <a style={styledLink} onClick={toggleShow} href="#Knights">
            Knights
          </a>
          <br />
          <br />
        <a style={styledLink} onClick={toggleShow} href="#Vikings">
            Vikings
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={toggleShow} href="#Samurai">
            Samurai
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={toggleShow} href="#WuLin">
            Wu Lin
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={toggleShow}href="#Outlanders">
            Outlanders
          </a>
          <br />
          <br />
          <br />
          <br />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


const Characters = () => {
  return (
    <div id="top">
      <NavPane placement={"start"} />
      <div className="mx-5 mt-5">
        <center>
          <p className="lead">Welcome to the For Honor Information Hub!</p>
          <p className="lead">
            In this webapp you will find hero frame data, punishes, guides,
            general information, and links to pretty much everything you need to
            know to level-up your For Honor skills - whether you are a beginner
            looking to learn the basics, or an advanced player looking to get
            specific information.
          </p>
          <p className="lead">
            Select a hero below to go to their page, or utilise the navigation
            menu at the top to traverse the Infohub.
          </p>
          <p className="lead">
            The bottom left menu can be used to quickly navigate and scroll to different sections on pages. The bottom right menu in character pages can be used to filter what information is seen on cards.
          </p>
          <p className="lead">
            Join the             <a
              className="styledLinks"
              style={{ textDecoration: "none"}}
              href="https://discord.gg/Ej7Wk4mMxh"
              target="_blank" rel="noreferrer"
            >
              discord server
            </a> to provide feedback/corrections and suggestions on the new Infohub.
          </p>
        </center>
      </div>
      <div className="col-lg-12">
        <Container className="text-center">
          <Row xxl={5} xl={4} lg={3} sm={2} xs={1}>
            <Col xs={{ order: 1 }}>
              <div className="KnightIcon"/>
              <div id="Knights" className="KnightDivider">
                <h1
                  style={{
                    backgroundColor: "#C29124",
                    padding: 4,
                    color: "white", textShadow: "2px 2px rgb(49, 49, 49)"
                  }}
                >
                  Knights
                </h1>
                <Row className="text-center">
                    <a className="charactericon Warden" title="Warden" href="/character?=1">
                      Warden
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Conqueror" title="Conqueror" href="/character?=2">
                      Conqueror
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Peacekeeper" title="Peacekeeper" href="/character?=3">
                      Peacekeeper
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Lawbringer" title="Lawbringer" href="/character?=4">
                      Lawbringer
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Centurion" title="Centurion" href="/character?=5">
                      Centurion
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Gladiator" title="Gladiator" href="/character?=6">
                      Gladiator
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon BlackPrior" title="BlackPrior" href="/character?=7">
                      Black Prior
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Warmonger" title="Warmonger" href="/character?=8">
                      Warmonger
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Gryphon" title="Gryphon" href="/character?=9">
                      Gryphon
                    </a>
                </Row>
              </div>
              <br />
            </Col>
            <Col xs={{ order: 2 }}>
            <div className="VikingIcon"/>
              <div id="Vikings" className="VikingDivider">
                <h1 style={{ backgroundColor: "#821515", padding: 4,
                    color: "white", textShadow: "2px 2px rgb(49, 49, 49)"}}>
                  Vikings
                </h1>
                <Row className="text-center">
                    <a className="charactericon Raider" title="Raider" href="/character?=10">
                      Raider
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Warlord" title="Warlord" href="/character?=11">
                      Warlord
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Berserker" title="Berserker" href="/character?=12">
                      Berserker
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Valkyrie" title="Valkyrie" href="/character?=13">
                      Valkyrie
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Highlander" title="Highlander" href="/character?=14">
                      Highlander
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Shaman" title="Shaman" href="/character?=15">
                      Shaman
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Jormungandr" title="Jormungandr" href="/character?=16">
                      Jormungandr
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Varangian" title="Varangian Guard" href="/character?=34">
                    Varangian Guard
                    </a>
                </Row>
              </div>
              <br />
            </Col>
            <Col xs={{ order: 3 }}>
            <div className="SamuraiIcon"/>
              <div id="Samurai" className="SamuraiDivider">
                <h1 style={{ backgroundColor: "#15897d", padding: 4,
                    color: "white", textShadow: "2px 2px rgb(49, 49, 49)"}}>
                  Samurai
                </h1>
                <Row className="text-center">
                    <a className="charactericon Kensei" title="Kensei" href="/character?=17">
                      Kensei
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Shugoki" title="Shugoki" href="/character?=18">
                      Shugoki
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Orochi" title="Orochi" href="/character?=19">
                      Orochi
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Nobushi" role="button" title="Nobushi" href="/character?=20">
                      Nobushi
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Shinobi" title="Shinobi" href="/character?=21">
                      Shinobi
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Aramusha" title="Aramusha" href="/character?=22">
                      Aramusha
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Hitokiri" title="Hitokiri" href="/character?=23">
                      Hitokiri
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Kyoshin" title="Kyoshin" href="/character?=24">
                      Kyoshin
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Sohei" title="Sohei" href="/character?=35">
                    Sohei
                    </a>
                </Row>
              </div>
              <br />
            </Col>
            <Col xs={{ order: 4 }}>
            <div className="WuLinIcon"/>
              <div id="WuLin" className="WuLinDivider">
                <h1 style={{ backgroundColor: "#68348A", padding: 4,
                    color: "white", textShadow: "2px 2px rgb(49, 49, 49)"}}>
                  Wu Lin
                </h1>
                <Row className="text-center">
                    <a className="charactericon Tiandi" title="Tiandi" href="/character?=25">
                      Tiandi
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon JiangJun" title="JiangJun" href="/character?=26">
                      Jiang Jun
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Nuxia" title="Nuxia" href="/character?=27">
                      Nuxia
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Shaolin" title="Shaolin" href="/character?=28">
                      Shaolin
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Zhanhu" title="Zhanhu" href="/character?=29">
                      Zhanhu
                    </a>
                </Row>
              </div>
              <br />
            </Col>
            <Col xs={{ order: 5 }}>
            <div className="OutlanderIcon"/>
              <div id="Outlanders" className="OutlanderDivider">
                <h1 style={{ backgroundColor: "#005DA0", padding: 4,
                    color: "white", textShadow: "2px 2px rgb(49, 49, 49)"}}>
                  Outlanders
                </h1>
                <Row className="text-center">
                    <a className="charactericon Pirate" title="Pirate" href="/character?=30">
                      Pirate
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Medjay" title="Medjay" href="/character?=31">
                      Medjay
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Afeera" title="Afeera" href="/character?=32">
                      Afeera
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Ocelotl" title="Ocelotl" href="/character?=33">
                      Ocelotl
                    </a>
                </Row>
                <Row className="text-center">
                    <a className="charactericon Khatun" title="Khatun" href="/character?=36">
                      Khatun
                    </a>
                </Row>
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Characters;
